var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-main"},[_c('vxe-toolbar',{ref:"refToolbar",attrs:{"size":"mini","export":"","print":"","custom":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('el-button',{attrs:{"type":"success","size":"mini"},on:{"click":_vm.itemAdd}},[_vm._v("新增参数")])]},proxy:true}])}),_c('vxe-grid',{ref:"refTable",attrs:{"size":"mini","max-height":this.$util.getViewHeight() - 150,"border":"","show-overflow":"","highlight-current-row":"","loading":_vm.loading,"row-config":{ useKey: true, keyField: 'id' },"export-config":{
      type: ['xlsx'],
      types: ['xlsx', 'csv', 'html', 'xml', 'txt'],
      isFooter: true,
      isColgroup: true
    },"tree-config":{
      transform: false,
      expandAll: false,
      showLine: true,
      childrenField: 'children',
      reserve: true
    },"data":_vm.tableDataMain,"columns":_vm.tableColumn},scopedSlots:_vm._u([{key:"header_autocomplete",fn:function({ column }){return [_c('c-table-header',{attrs:{"title":column.title,"field":column.field,"url":_vm.headerFilterUrl},on:{"change":_vm.headerFilterChange}})]}},{key:"default_operate",fn:function({ row }){return [_c('vxe-button',{attrs:{"icon":"el-icon-edit-outline","title":"编辑","circle":""},on:{"click":function($event){return _vm.itemEdit(row)}}}),_c('vxe-button',{attrs:{"icon":"el-icon-delete","title":"删除","circle":""},on:{"click":function($event){return _vm.itemDelete(row)}}})]}},{key:"pager",fn:function(){return [_c('div'),_c('vxe-pager',_vm._b({on:{"page-change":_vm.pageChange}},'vxe-pager',_vm.pageOptions,false))]},proxy:true}])}),(_vm.isShowPopupDict)?_c('PopupDict',{attrs:{"show":_vm.isShowPopupDict,"data":_vm.itemObj},on:{"update:show":function($event){_vm.isShowPopupDict=$event},"update:data":function($event){_vm.itemObj=$event},"success":_vm.getListData}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }